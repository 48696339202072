import React, {useState, useEffect, useCallback} from "react";

import Flatpickr from "react-flatpickr";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    Label,
    Input,
    Nav,
    NavItem,
    NavLink,
    TabPane,
    CardHeader, TabContent
} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import { Russian } from "flatpickr/dist/l10n/ru.js"
import styles from "../Expenses/Expenses.module.css";
import PieChartAnalytics from "./PieChartAnalytics";
import {
    getAccountsApi,
    getCalculateProfit,
    getExpenseCategoriesApi,
    getSalesChannelsApi,
    getStoragesApi,
} from "../../../helpers/backend_helper";
import Loader from "../../../Components/Common/Loader";
import PrintButton from "../../Prints/PrintButton";
import TagInput from "../../../Components/Common/TagInput";
import classnames from "classnames";
import statistics from "../../../assets/images/custom/statistics.png";
import expenses from "../../../assets/images/custom/expenses.png";
import {Switch} from "@mui/material";
import ValidityIndicator from "../../../Components/Common/ValidityIndicator";
import EventLogTable from "../../../Components/Common/EventLogTable";
import {PieChart} from "../../../Components/Charts/Echarts/ECharts";
import AnnualStats from "./AnnualStats";


function Profit() {
    const [profitData, setProfitData] = useState({});
    const [annualProfitStatistics, setAnnualProfitStatistics] = useState({});
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState({});

    const [salesChannels, setSalesChannels] = useState([]);
    const [selectedSalesChannel, setSelectedSalesChannel] = useState("");

    const [storages, setStorages] = useState([]);
    const [selectedStorage, setSelectedStorage] = useState("");
    const [accounts, setAccounts] = useState({});
    const [expensesCategories, setExpensesCategories] = useState([]);
    const [activeTab, setactiveTab] = useState(1);
    const [passedSteps, setPassedSteps] = useState([1]);


    function toggleTab(tab) {
        if (activeTab !== tab) {
            var modifiedSteps = [...passedSteps, tab];

            if (tab >= 1 && tab <= 4) {
                setactiveTab(tab);
                setPassedSteps(modifiedSteps);
            }
        }
    }

    const fetchExpensesCategories = () => {
        let data = {};
        if ('dates' in filters) {
            Object.keys(filters['dates']).forEach(key => {
                data[key] = filters['dates'][key];
            });
        }
        getExpenseCategoriesApi(data)
            .then((response) => {
                setExpensesCategories(response);
            })
            .catch(() => {});
    };

    useEffect(() => {
        fetchExpensesCategories();
    }, [filters]);

    const hasPermissions = (permissions) => {
        return accounts.results && accounts.current && permissions.includes(accounts.results[accounts.current].access)
    };
    const getAccessObjects = () => {
        return String(accounts?.results && accounts.current && accounts?.results[accounts.current]?.access_objects);
    };

    useEffect(() => {
    const fetchAccounts = () => {
      getAccountsApi()
      .then((data) => {
        setAccounts(data);
      })
      .catch(() => {
        // toast.error("Не удалось получить список аккаунтов", {
        //   position: "top-right",
        // });
      });
    };
    fetchAccounts();
    }, []);

    const fetchSalesChannels = () => {
      getSalesChannelsApi()
        .then((response) => {
          setSalesChannels(response);
        })
        .catch(() => {});
    };
    const fetchStorages = () => {
      getStoragesApi()
        .then((response) => {
          setStorages(response);
        })
        .catch((error) => {});
    };

    useEffect(() => {
        // Function to fetch profit data
        const fetchProfitData = async () => {
          try {
            setLoading(true);
            let data = {};
            if ('dates' in filters) {
              Object.keys(filters['dates']).forEach(key => {
                data[key] = filters['dates'][key];
              });
            }
            if (selectedStorage) {
                data.storage = selectedStorage;
            }
            if (selectedSalesChannel) {
                data.sales_channel = selectedSalesChannel;
            }
            // Make the API call to fetch profit data
            const response = await getCalculateProfit(data);
            // Extract profit data and totals from the response
            const { results, totals, turnover, no_turnover } = response;
            // Set the profit data and totals in the state
            setProfitData({ results, totals, turnover, no_turnover });
          } catch (error) {
            toast.error('Не удалось получить данные о прибыли');
          } finally {
            setLoading(false);
          }
        };
        // Call the fetchProfitData function
        fetchProfitData();
    }, [selectedStorage, selectedSalesChannel, filters]);

    useEffect(() => {
      fetchSalesChannels();
      fetchStorages();
    }, []);

    /// Поиск ///
    const [searchProfitDataTerm, setSearchProfitDataTerm] = useState("");
    const handleProfitDataChange = (event) => {
        setSearchProfitDataTerm(event.target.value);
    };
    const handleProductsChange = (tags) => {
        setSearchProfitDataTerm(tags);
    };

    const filteredProfitData = profitData?.results?.length > 0 && profitData.results.filter((profit) => {
        // const profitDataMatch = (
        //     profit.title.toLowerCase().includes(searchProfitDataTerm.toLowerCase()) ||
        //     profit.code.toLowerCase().includes(searchProfitDataTerm.toLowerCase())
        // );
        const profitDataMatch = searchProfitDataTerm.length === 0 ||
            searchProfitDataTerm.some(tag =>
                profit.title.toLowerCase().includes(tag.toLowerCase()) ||
                profit.code.toLowerCase().includes(tag.toLowerCase())
            );
        return !searchProfitDataTerm || profitDataMatch;
    });

    const handleStorageChange = (storageName) => {
      setSelectedStorage(storageName);
    };
    const handleSalesChannelChange = (salesChannelName) => {
      setSelectedSalesChannel(salesChannelName);
    };

    document.title = "CRMBEK : Прибыль";

    return (
        <React.Fragment>
            <div className="page-content pb-5">
                {hasPermissions(['owner']) ||
                getAccessObjects().includes('analytics') ? (
                <Container fluid>
                    <div className="mb-2" style={{marginLeft: '8.3%'}}>
                        <PrintButton json={profitData} filters={{
                            dates: filters['dates'],
                            storage: selectedStorage,
                            sales_channel: selectedSalesChannel
                        }} documentType={'profit'} button={
                            <button
                                style={{ padding: "5px 15px" }}
                                className="btn btn-outline-primary add-btn me-1-bin-2-line"
                                onClick={() => {
                                }}
                            >
                                <img src="/printing.png" alt="printing" />
                                <span style={{ marginLeft: "6px" }}>Печать</span>
                            </button>
                        } />
                    </div>
                    <Col lg={12} style={{ margin: "0 auto" }}>
                        <Card className="" style={{backgroundColor: 'transparent'}}>
                            <CardBody>
                                <div className="listjs-table" id="customerList">
                                    <Row className="g-4 mb-3">
                                        <Col className={styles.width}>
                                            <section className={styles.filter_section}>
                                                <h3
                                                    className={styles.title}
                                                    // style={{marginBottom: '40px'}}
                                                >Калькулятор <br/>прибыли</h3>

                                                <div className={styles.date_range}>
                                                    <Label
                                                        htmlFor="product-field"
                                                        className={`form-label ${styles.filter_label}`}
                                                    >
                                                        Дата
                                                    </Label>
                                                    <div className={`input-group`}>
                                                        <span className="input-group-text" id="basic-addon1"><i className="ri-calendar-2-line"></i></span>
                                                        <Flatpickr
                                                            placeholder="Выберите дату/период"
                                                            className={`form-control`}
                                                            options={{
                                                                mode: "range",
                                                                dateFormat: "d M, Y",
                                                                locale: Russian,
                                                                onChange: function (selectedDates, dateStr, instance) {
                                                                    const formattedDates = selectedDates.map(date =>
                                                                        date.toLocaleDateString(
                                                                            'ru',
                                                                            { year: 'numeric', month: '2-digit', day: '2-digit' }
                                                                        )
                                                                            .replace('.', '-')
                                                                            .replace('.', '-')
                                                                            .split('-')
                                                                            .reverse()
                                                                            .join('-')
                                                                    );
                                                                    if (formattedDates.length === 2) {
                                                                        formattedDates[0] !== formattedDates[1]
                                                                            ? setFilters({ ...filters, dates: { range_start: formattedDates[0], range_end: formattedDates[1] } })
                                                                            : setFilters({ ...filters, dates: { date: formattedDates[0] } });
                                                                    }/* else {
                                                                        let updatedFilters = { ...filters };
                                                                        delete updatedFilters.dates;
                                                                        setFilters(updatedFilters)
                                                                    }*/

                                                                },
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={styles.filter_element}>
                                                    <Label
                                                        htmlFor="product-field"
                                                        className={`form-label ${styles.filter_label}`}
                                                    >
                                                        Товары
                                                    </Label>
                                                    {/*<Input*/}
                                                    {/*    type="text"*/}
                                                    {/*    placeholder="Поиск по товарам"*/}
                                                    {/*    value={searchProfitDataTerm}*/}
                                                    {/*    onChange={handleProfitDataChange}*/}
                                                    {/*/>*/}
                                                    <TagInput
                                                        maxWidth='300px'
                                                        tags={searchProfitDataTerm}
                                                        onTagsChange={(tags) =>
                                                            handleProductsChange(tags)
                                                        }
                                                    />
                                                </div>
                                                <div className={styles.filter_element}>
                                                    <Label
                                                        htmlFor="product-field"
                                                        className={`form-label ${styles.filter_label}`}
                                                    >
                                                        Склад
                                                    </Label>
                                                    <select
                                                        value={selectedStorage}
                                                        onChange={(e) => {
                                                            const selectedStorage = e.target.value;
                                                            handleStorageChange(selectedStorage);
                                                        }}
                                                        className="form-select"
                                                        aria-label="Default select example"
                                                    >
                                                        <option style={{fontWeight: "bold"}} value="">
                                                            Склад
                                                        </option>
                                                        {storages.map((storage) => (
                                                            <option key={storage.id} value={storage.name}>
                                                                {storage.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className={styles.filter_element}>
                                                    <Label
                                                        htmlFor="product-field"
                                                        className={`form-label ${styles.filter_label}`}
                                                    >
                                                        Канал продаж
                                                    </Label>
                                                    <select
                                                        value={selectedSalesChannel}
                                                        onChange={(e) => {
                                                            const selectedSalesChannel = e.target.value;
                                                            handleSalesChannelChange(selectedSalesChannel);
                                                        }}
                                                        className="form-select"
                                                        aria-label="Default select example"
                                                    >
                                                        <option style={{fontWeight: "bold"}} value="">
                                                            Канал продаж
                                                        </option>
                                                        {salesChannels.map((salesChannel) => (
                                                            <option key={salesChannel.id} value={salesChannel.name}>
                                                                {salesChannel.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </section>
                                            <Row>
                                                <Col xxl="10" xl="11" xs="12">
                                                    <Card
                                                        className="mb-0 mt-4"
                                                        style={{
                                                            backgroundColor: 'transparent',
                                                            boxShadow: 'unset'
                                                        }}>
                                                        <CardBody>
                                                            {/*<div className="step-arrow-nav mt-n3 mx-n3 mb-3">*/}
                                                            <div className="step-arrow-nav mt-n3 mx-n3 mb-3">
                                                                <Nav
                                                                    // className="nav-pills nav-justified custom-nav"
                                                                    role="tablist"
                                                                >
                                                                    <NavItem role="presentation" className="position-relative">
                                                                        <NavLink href="#"
                                                                            // style={{padding: '34px'}}
                                                                                 className={classnames({
                                                                                     active: activeTab === 1,
                                                                                     done: (activeTab <= 4 && activeTab >= 0)
                                                                                 }, "fs-16 lh-1 text-black")}
                                                                                 onClick={() => {
                                                                                     toggleTab(1);
                                                                                 }}
                                                                        >
                                                                            {/*<i className="ri-user-2-line fs-16 p-2 bg-primary-subtle text-primary rounded-circle align-middle me-2"></i>*/}
                                                                            <span className="mx-2">Показатели</span>
                                                                            <img width={23} height={23} src={statistics} alt="statistics.png"/>
                                                                        </NavLink>
                                                                    </NavItem>
                                                                    <NavItem role="presentation" className="position-relative">
                                                                        <NavLink href="#"
                                                                            // style={{padding: '34px'}}
                                                                                 className={classnames({
                                                                                     active: activeTab === 2,
                                                                                     done: activeTab <= 4 && activeTab > 1
                                                                                 }, "fs-16 lh-1 text-black")}
                                                                                 onClick={() => {
                                                                                     toggleTab(2);
                                                                                 }}
                                                                        >
                                                                            {/*<i className="ri-truck-line fs-16 p-2 bg-primary-subtle text-primary rounded-circle align-middle me-2"></i>*/}
                                                                            <span className="mx-2">Расходы</span>
                                                                            <img width={23} height={23} src={expenses} alt="expenses.png"/>
                                                                        </NavLink>
                                                                    </NavItem>
                                                                </Nav>
                                                            </div>
                                                            <TabContent activeTab={activeTab}>
                                                                <TabPane tabId={1} id="pills-bill-info">
                                                                    {/*<div>
                                                                        <h5 className="mb-1">Основные настройки</h5>
                                                                        <p className="text-muted mb-4">
                                                                            Пожалуйста заполните поля ниже для активации
                                                                        </p>
                                                                    </div>*/}
                                                                    <AnnualStats/>

                                                                </TabPane>

                                                                <TabPane tabId={2}>
                                                                    {/*<div>
                                                                        <h5 className="mb-1">Журнал событий интеграции</h5>
                                                                        <p className="text-muted mb-4">
                                                                            Здесь отображается всё что происходит в пределах интеграции
                                                                        </p>
                                                                    </div>*/}

                                                                    {/*<div className="d-flex align-items-start gap-3 mt-4">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-light btn-label previestab"
                                                                            onClick={() => {
                                                                                toggleTab(activeTab - 1);
                                                                            }}
                                                                        >
                                                                            <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>
                                                                            Вернуться к настройкам
                                                                        </button>
                                                                    </div>*/}
                                                                    {/*{(profitData?.totals?.total_revenue && profitData?.totals?.total_cost)*/}
                                                                    {/*    ?*/}
                                                                    {/*    <div className="card-body">*/}
                                                                    {/*        /!*{profitData?.turnover && profitData?.no_turnover &&*/}
                                                                    {/*            <PieChartAnalytics*/}
                                                                    {/*                data={[profitData.turnover, profitData.no_turnover]}*/}
                                                                    {/*            />*/}
                                                                    {/*        }*!/*/}
                                                                    {/*        <PieChartAnalytics*/}
                                                                    {/*            data={[profitData.totals.total_revenue, profitData.totals.total_cost]}*/}
                                                                    {/*            labels={['Оборот', 'Себестоимость товаров']}*/}
                                                                    {/*        />*/}
                                                                    {/*    </div>*/}
                                                                    {/*    :*/}
                                                                    {/*    null*/}
                                                                    {/*}*/}
                                                                    {expensesCategories?.length > 0 && (
                                                                        <div className="card-body pb-0 pt-0 px-0">
                                                                            {/* mx-xl-5 px-xl-5 <PieChart data={expensesCategories} selectedCategory={selectedCategory} />*/}
                                                                            <PieChart
                                                                                data={expensesCategories}
                                                                                selectedCategory={null}
                                                                                dataColors='[
                                                                                    "#FFBB93",
                                                                                    "#8BC34A",
                                                                                    "#29B6F6",
                                                                                    "#FF9AAE",
                                                                                    "#7EC8F3",
                                                                                    "#FFDF94",
                                                                                    "#8AD8D8",
                                                                                    "#BFA0FF",
                                                                                    "#FFBF85",
                                                                                    "#75E075",
                                                                                    "#FFBB93",
                                                                                    "#FF99B2",
                                                                                    "#C0CA33",
                                                                                    "#66F399",
                                                                                    "#FFFEBE",
                                                                                    "#B9D9EF",
                                                                                    "#A1887F",
                                                                                    "#FFD54F",
                                                                                    "#7986CB",
                                                                                    "#4DD0E1",
                                                                                    "#FF6384",
                                                                                    "#36A2EB",
                                                                                    "#FFCE56",
                                                                                    "#fffd9c"
                                                                                ]'
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </TabPane>
                                                            </TabContent>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>

                                {filteredProfitData ? (
                                    <div className="listjs-table" id="customerList">
                                        <div className="table-responsive table-card mt-1" style={{
                                            maxHeight: "600px",
                                            overflowY: "auto",
                                            position: "relative"
                                            // border: 0
                                        }}>
                                            <table
                                                id="customerTable"
                                                style={{width: "100%", backgroundColor: 'white'}}
                                                className={styles.table_profit}
                                            >
                                                <thead className={styles.title_thead}>
                                                <tr >
                                                    <th className="px-3">
                                                    <span className="py-1">
                                                        Товар
                                                    </span>
                                                    </th>
                                                    <th className={`${styles.border_l} px-3`}>
                                                    <span className="py-3">
                                                        Артикул
                                                    </span>
                                                    </th>
                                                    <th className="px-3">
                                                    <span>
                                                        кол-во продаж
                                                    </span>
                                                    </th>
                                                    <th > {/*className={`${styles.border_lr} px-3`}*/}
                                                    <span>
                                                        себест. шт.
                                                    </span>
                                                    </th>
                                                    <th className="px-3">
                                                    <span>
                                                        цена продажи
                                                    </span>
                                                    </th>
                                                    <th className="px-3">
                                                    <span>
                                                        доставка сумма
                                                    </span>
                                                    </th>
                                                    <th className="px-3">
                                                    <span>
                                                        комиссия сумма
                                                    </span>
                                                    </th>
                                                    <th className="px-3">
                                                    <span>
                                                        налог 3%
                                                    </span>
                                                    </th>
                                                    <th className="px-3">
                                                    <span>
                                                        сумма продаж
                                                    </span>
                                                    </th>
                                                    <th className="px-3">
                                                    <span>
                                                        сумма себес.
                                                    </span>
                                                    </th>
                                                    <th className="px-3">
                                                    <span style={{fontWeight: 'bold'}}>
                                                        прибыль
                                                    </span>
                                                    </th>
                                                    {/*<th className={`${styles.border_l} px-3`}>
                                                    <span style={{fontWeight: 'bold'}}і>
                                                        рентабильность
                                                    </span>
                                                    </th>*/}
                                                </tr>
                                                </thead>
                                                <tbody className={styles.scroll}>
                                                {loading ? ( // Show loader only when loading
                                                        <Loader />
                                                    ) :
                                                    (filteredProfitData.map((item, key) => (
                                                        <tr key={key}>
                                                            <td className="d-flex align-items-center gap-3"
                                                                // style={{marginLeft: '1rem'}}
                                                            >
                                                                {item.image && (
                                                                    <div
                                                                        className="d-flex align-items-center"
                                                                        style={{width: '45px', height: '45px'}}
                                                                    >
                                                                        <img src={item.image} alt="товар"
                                                                             style={{maxHeight: '100%', maxWidth: '100%', margin: '0 auto'}}
                                                                        />
                                                                    </div>
                                                                )}
                                                                <div className="text-start" style={{width: '77%', minWidth: '180px'}}>
                                                                    <span style={{wordBreak: 'break-word'}}>{item.title}</span>
                                                                </div>
                                                            </td>
                                                            <td className={`${styles.border_l} px-3 text-start`}>
                                                                {item.code}
                                                            </td>
                                                            <td>
                                                                {item.total_quantity}
                                                            </td>
                                                            <td > {/*className={styles.border_lr}*/}
                                                                {item.cost}
                                                            </td>
                                                            <td>
                                                                {item.average_price}
                                                            </td>
                                                            <td>
                                                                {item.delivery_tax}
                                                            </td>
                                                            <td>
                                                                {item.total_commission}
                                                            </td>
                                                            <td>
                                                                {item.tax}
                                                            </td>
                                                            <td className={`${styles.border_l} px-3`}>
                                                                {item.total_revenue}
                                                            </td>
                                                            <td>
                                                                {item.total_cost}
                                                            </td>
                                                            <td style={{fontWeight: 'bold'}}>
                                                                {item.total_profit}
                                                            </td>
                                                            {/*<td className={styles.border_l} style={{fontWeight: 'bold'}}>
                                                                {item.profitability}
                                                            </td>*/}
                                                        </tr>
                                                    )))}
                                                </tbody>
                                                <tfoot className={styles.tfoot}>
                                                <tr>
                                                    <td>
                                                        <span style={{fontWeight: "bold", fontSize: '14px'}}>ИТОГО</span>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                    <span style={{fontWeight: "bold"}}>
                                                        {profitData?.totals ? profitData.totals.total_revenue : 0}
                                                    </span>
                                                    </td>
                                                    <td>
                                                    <span style={{fontWeight: "bold"}}>
                                                        {profitData?.totals ? profitData.totals.total_cost : 0}
                                                    </span>
                                                    </td>
                                                    <td>
                                                    <span style={{fontWeight: "bold", fontSize: '17px'}}>
                                                        {profitData?.totals ? profitData.totals.total_profit : 0}
                                                    </span>
                                                    </td>
                                                    <td></td>
                                                </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                ) : <h4 className="text-center mb-5 mt-5" style={{opacity: 0.5}}>Данных о прибыли не найдено</h4>}

                            </CardBody>
                            <ToastContainer closeButton={false} />
                        </Card>
                    </Col>
                </Container>
                    ) : (
                  <h3 className="text-center my-5" style={{opacity: 0.3}}>У вас нет прав для просмотра данной страницы</h3>
                )}
            </div>
        </React.Fragment>)
}

export default Profit;