import React from "react";
import CountUp from "react-countup";

const Widgets = ({ stats }) => {
    const widgetsData = [
        {
            id: 1,
            label: "Годовая прибыль",
            badge: "ri-arrow-up-circle-line text-success",
            icon: "ri-exchange-dollar-line",
            counter: stats.annual_profit,
            decimals: 2,
            prefix: "",
            suffix: " ₸",
            separator: ","
        },
        {
            id: 2,
            label: "Количество заказов за год",
            badge: "ri-arrow-up-circle-line text-success",
            icon: "ri-service-line",
            counter: stats.annual_deals,
            decimals: 0,
            prefix: "",
            suffix: "",
            separator: ","
        },
        {
            id: 3,
            label: "Дневная средняя прибыль",
            badge: "ri-arrow-up-circle-line text-success",
            icon: "ri-trophy-line",
            counter: stats.daily_average_income,
            decimals: 2,
            prefix: "",
            suffix: " ₸",
            separator: ","
        },
        /*{
            id: 4,
            label: "Пустой блок",
            badge: "ri-information-line text-muted",
            icon: "ri-more-line",
            counter: 0,
            decimals: 0,
            prefix: "",
            suffix: "",
        },*/
    ];

    return (
        <div className="row row-cols-md-2 row-cols-1 g-4 mt-1">
            {widgetsData.map((widget) => (
                <div className="col" key={widget.id}>
                    <div className="card crm-widget h-100">
                        <div className="card-body">
                            <h5 className="text-muted text-uppercase fs-13">
                                {widget.label}
                                <i className={`${widget.badge} fs-18 float-end align-middle`}></i>
                            </h5>
                            <div className="d-flex align-items-center mt-3">
                                <i className={`${widget.icon} display-6 text-muted`}></i>
                                <h2 className="ms-3 mb-0">
                                    <CountUp
                                        start={0}
                                        end={widget.counter}
                                        decimals={widget.decimals}
                                        prefix={widget.prefix}
                                        suffix={widget.suffix}
                                        separator={widget.separator}
                                        duration={2}
                                    />
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Widgets;


/*
import React from 'react';
import { crmWidgets } from "./dashboardcrm";
import CountUp from "react-countup";

const Widgets = () => {
    return (
        <React.Fragment>
            <div className="col-xl-12">
                <div className="card crm-widget">
                    <div className="card-body p-0">
                        <div className="row row-cols-xxl-5 row-cols-md-3 row-cols-1 g-0">
                            {(crmWidgets).map((widget, index) => (
                                <div className="col" key={index}>
                                    <div className="py-4 px-3">
                                        <h5 className="text-muted text-uppercase fs-13">{widget.label}<i className={widget.badge + " fs-18 float-end align-middle"}></i></h5>
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0">
                                                <i className={widget.icon + " display-6 text-muted"}></i>
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h2 className="mb-0"><span className="counter-value" data-target="197">
                                                    <CountUp
                                                        start={0}
                                                        prefix={widget.prefix}
                                                        suffix={widget.suffix}
                                                        separator={widget.separator}
                                                        end={widget.counter}
                                                        decimals={widget.decimals}
                                                        duration={4}
                                                    />
                                                </span></h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Widgets;*/
