import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart, CategoryScale } from 'chart.js';
Chart.register(CategoryScale);
import styles from '../Analytics/Analytics.module.css';

const PieChart = ({ data }) => {
  const [position, setPosition] = useState('right');
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const legendPosition = () => {
      if (window.innerWidth > 600) {
        setPosition('right');
      } else {
        setPosition('top');
      }
    };

    legendPosition();

    window.addEventListener('resize', legendPosition);

    return () => {
      window.removeEventListener('resize', legendPosition);
    };
  }, []);

  useEffect(() => {
    if (data && Array.isArray(data)) {
      const colors = [
        "#FF6384",
        "#36A2EB",
        "#FFCE56",
        "#4BC0C0",
        "#9966FF",
        "#FF9F40",
        "#32CD32",
        "#FF0000",
        "#800080",
        "#00f080",
        "#fffd9c",
        "#92c1e8",
      ];

      const updatedChartData = {
        labels: data.map(item => item.title),
        datasets: [
          {
            data: data.map(item => item.total),
            backgroundColor: colors.map(color => color + '9A'),
            hoverBackgroundColor: colors,
            hoverBorderColor: "#fff"
          }
        ]
      };

      setChartData(updatedChartData);
    }
  }, [data]);

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Отключить фиксированное соотношение
    plugins: {
      legend: {
        position: "right",
        // position: "left",
        align: "start", // Легенда выровнена по верхнему краю
        labels: {
          font: {
            family: "Montserrat",
            size: 15,
          },
          // boxWidth: 12, // Размер квадрата
          padding: 1, // Отступы между элементами
          color: "#555", // Цвет текста
        },
        reverse: false, // Обычный порядок
      },
      // tooltip: {
      //   enabled: true,
      //   callbacks: {
      //     label: function (tooltipItem) {
      //       return tooltipItem.label + ": " + tooltipItem.raw;
      //     },
      //   },
      // },
    },
    layout: {
      padding: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
    },
    // animation: {
    //   duration: 1500,
    //   easing: "easeOutBounce",
    // },
  };

  // const options = {
  //   plugins: {
  //     legend: {
  //       position: position,
  //       labels: {
  //         font: {
  //           family: "Montserrat",
  //           size: "15px"
  //         },
  //         padding: 1
  //       }
  //     }
  //   }
  // };
  /*const options = {
    plugins: {
      legend: {
        position: "right", // Легенда справа
        labels: {
          font: {
            family: "Poppins",
            size: 16, // Размер шрифта
          },
          // boxWidth: 14, // Размер квадратов легенды
        },
      },
    },
    maintainAspectRatio: true, // Позволяет кастомизировать высоту и ширину
  };*/

  return (
      /* className={styles.container}*/
    <div style={{width: '100%'}}>
      {chartData && <Pie data={chartData} options={options} height={1000} className="chartjs-chart" />}
    </div>
  );

};

export default PieChart;