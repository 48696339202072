import React, {useEffect, useState} from "react";

//Import Breadcrumb
import BreadCrumb from "../../../../src/Components/Common/BreadCrumb";
import FeatherIcon from 'feather-icons-react';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

import classnames from "classnames";
import {Link} from "react-router-dom";
import {
  getIntegrationApi,
  getStatusesApi,
  updateIntegrationApi,
  generatePricelistApi
} from "../../../helpers/backend_helper";
import {toast, ToastContainer} from "react-toastify";
import ValidityIndicator from "../../../Components/Common/ValidityIndicator";
import EventLogTable from "../../../Components/Common/EventLogTable";
import {Switch} from "@mui/material";
import expenses from "../../../assets/images/custom/expenses.png";
import statistics from "../../../assets/images/custom/statistics.png";

const KaspiAPIIntegration = () => {
  const [errors, setErrors] = useState({});

  const [statuses, setStatuses] = useState([]);
  const [integration, setIntegration] = useState({
    "enabled": false,
    "api_token": "",
    "token_valid": true,
    "api_save_customer": false,
    "mapped_statuses": [  // writeOnly
      // {"fixed_status": "DELIVERY", "user_status": 3},
    ],
    "mapped_statuses_objects": {  // readOnly
      // "DELIVERY": 3
    },
    "kaspi_statuses": {  // readOnly
      // "SIGN_REQUIRED": "На подписании",
      // "PICKUP": "Самовывоз Каспи",
    },
    "statuses_filled": false,
    "last_days_create_archive": '1',
    "last_days_create_archive_options": [
        // ["1", "1 день"]
        // ["2", "2 дня"]
    ],
    "api_not_synced_orders": {},  // readOnly
    "event_logs": [
        // { formatted_created: '10.02.2024 08:30:15', log_type: 'Вход', description: 'Пользователь вошел в систему' },
        // { formatted_created: '10.02.2024 09:15:42', log_type: 'Выход', description: 'Пользователь вышел из системы' },
    ],  // readOnly
    "kaspi_company": '',
    "kaspi_merchant_id": '',
    "kaspi_password": '',
    "pricelist_xml": null,
    "pricelist_xlsx": null,
    "pricelist_update_enabled": false,
    "last_uploaded_formatted": null,
  });


  const [selectedCountry, setselectedCountry] = useState(null);
  const [selectedState, setselectedState] = useState(null);
  const [activeTab, setactiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [modal, setModal] = useState(false);
  const [deletemodal, setDeleteModal] = useState(false);


  const fetchIntegration = () => {
    getIntegrationApi()
        .then((response) => {
          setIntegration(response);
        })
        .catch((error) => {
        });
  };
  const fetchStatuses = () => {
    getStatusesApi()
        .then((response) => {
          setStatuses(response);
        })
        .catch((error) => {
        });
  };

  useEffect(() => {
    fetchIntegration();
    fetchStatuses();

    // if (!integration.kaspi_statuses) {
    //   const {mapped_statuses, ...updatedData} = integration;
    //   await updateIntegration(updatedData, 'Настройки интеграции инициализированы');
    // }
  }, []); // Empty dependency array to run this effect only on mount

  const updateIntegration = async (updatedData, successMsg) => {
    try {
      setErrors({});
      const response = await updateIntegrationApi(updatedData);
      setIntegration(response);
      toast.success(successMsg);
    } catch (error) {
      if (error?.response?.data?.errors && error?.response?.status === 400) {
        setErrors(error.response.data.errors);
      } else {
        toast.error("Не удалось обновить настройки интеграции");
      }
    }
  };

  const handleSaveStatusesClick = async () => {
    // Generate the structure for mapped_statuses
    const mappedStatusesData = generateMappedStatusesData();

    // Create the updatedData object with the generated mapped_statuses
    const updatedData = {
      ...integration, // Include other fields as is
      mapped_statuses: mappedStatusesData
    };

    // Call the updateIntegration function with the updatedData
    await updateIntegration(updatedData, 'Статусы обновлены');
  };

  const handleSaveIntegrationForm = async (event) => {
    event.preventDefault();
    const {mapped_statuses, ...updatedData} = integration;
    // Call the updateIntegration function with the updatedData
    await updateIntegration(updatedData, 'Настройки интеграции обновлены');
  }

  const handleInputChange = (event) => {
    if (event.target.type === "checkbox") {
      const { name, checked } = event.target;
      setIntegration({
        ...integration,
        [name]: checked
      });
    }else {
      let { name, value } = event.target;

      if (value === 'false') {
        value = false;
      }else if (value === 'true') {
        value = true;
      }

      setIntegration({
        ...integration,
        [name]: value
      });

      // if (event.target.type === "button") {
      //   const {mapped_statuses, ...updatedData} = integration;
      //   await updateIntegration(updatedData, 'Настройки интеграции обновлены');
      // }
    }
  };

  const handleUploadProducts = async (event) => {
    try {
      const response = await generatePricelistApi();
      if (response.success) {
        toast.success(response.message);
        fetchIntegration();
      }else{
        toast.error(response.message);
      }
    } catch (error) {
      if (error?.response?.data && error?.response?.status === 400) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Возникла ошибка. Повторите попытку позже");
      }
    }
  }

  // const generateMappedStatusesData = () => {
  //   return Object.entries(integration.mapped_statuses_objects)
  //     // .filter(([fixed_status, user_status]) => user_status !== '') // Filter out entries where user_status is blank
  //     .map(([fixed_status, user_status]) => ({
  //         fixed_status,
  //         user_status
  //     }));
  // };
  const generateMappedStatusesData = () => {
    const kaspiStatuses = integration.kaspi_statuses;
    // Sort based on kaspi_status_order

    return Object.entries(integration.mapped_statuses_objects)
        .filter(([fixed_status, user_status]) => user_status !== '') // Filter out entries where user_status is blank
        .map(([fixed_status, user_status]) => ({
          fixed_status,
          user_status,
          kaspi_status_order: Object.keys(kaspiStatuses).indexOf(fixed_status) // Get the index of fixed_status in kaspi_statuses
        }))
        .sort((a, b) => a.kaspi_status_order - b.kaspi_status_order);
};

  // const handleIntegrationFieldChange = (event) => {
  //   const { name, value } = event.target;
  //   setIntegration((prevFormData) => ({
  //     ...prevFormData,
  //     [name]: value,
  //   }));
  // };

  const handleStatusFieldChange = (fixedStatus, event) => {
  const { value } = event.target;
  setIntegration((prevData) => ({
    ...prevData,
    mapped_statuses_objects: {
      ...prevData.mapped_statuses_objects,
      [fixedStatus]: value
    }
  }));
};

  const getStatusForFixedStatus = (kaspiStatus) => {
    const mappedStatusesObj = integration.mapped_statuses_objects;
    if (mappedStatusesObj && kaspiStatus in mappedStatusesObj) {
      return mappedStatusesObj[kaspiStatus];
    }
    return ""; // Or any default value you want to return
  };

  // -------------------------------------------------------------------------------------------------------------------

  const toggledeletemodal = () => {
    setDeleteModal(!deletemodal);
  };

  const togglemodal = () => {
    setModal(!modal);
  };

  /*function handleSelectCountry(selectedCountry) {
    setselectedCountry(selectedCountry);
  }

  function handleSelectState(selectedState) {
    setselectedState(selectedState);
  }*/

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];

      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  }

  /*const productState = [
    {
      options: [
        {label: "Select State...", value: "Select State"},
        {label: "Alabama", value: "Alabama"},
        {label: "Alaska", value: "Alaska"},
        {label: "American Samoa", value: "American Samoa"},
        {label: "California", value: "California"},
        {label: "Colorado", value: "Colorado"},
        {label: "District Of Columbia", value: "District Of Columbia"},
        {label: "Florida", value: "Florida"},
        {label: "Georgia", value: "Georgia"},
        {label: "Guam", value: "Guam"},
        {label: "Hawaii", value: "Hawaii"},
        {label: "Idaho", value: "Idaho"},
        {label: "Kansas", value: "Kansas"},
        {label: "Louisiana", value: "Louisiana"},
        {label: "Montana", value: "Montana"},
        {label: "Nevada", value: "Nevada"},
        {label: "New Jersey", value: "New Jersey"},
        {label: "New Mexico", value: "New Mexico"},
        {label: "New York", value: "New York"},
      ],
    },
  ];*/
  /*const productCountry = [
    {
      options: [
        {label: "Select Country...", value: "Select Country"},
        {label: "United States", value: "United States"},
      ],
    },
  ];*/

  document.title = "CRMBEK : Настройки интеграции";

  return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid style={{marginBottom: '48px'}}>
            <BreadCrumb pageTitle="Интеграции" title="API интеграция"/>

            <Row>
              <Col xl="8">
                <Card>
                  <CardBody className="checkout-tab">
                    <Form autoComplete="off" onSubmit={handleSaveIntegrationForm}>
                      <div className="step-arrow-nav mt-n3 mx-n3 mb-3">
                        <Nav
                            className="nav-pills nav-justified custom-nav"
                            role="tablist"
                        >
                          <NavItem role="presentation" className="position-relative">
                            {integration?.statuses_filled === false &&
                                integration?.enabled === true &&
                                activeTab !== 1 && (
                                <div className="position-absolute d-inline-block"
                                     style={{right: '10px', top: '10px', color: '#ff5454'}}>
                                  Не заполнены статусы
                                  &nbsp;
                                  <span
                                    className="fw-bold"
                                    style={{
                                      display: 'inline-block',
                                      width: '1.3em',
                                      height: '1.3em',
                                      border: '2px solid #ff5454',
                                      borderRadius: '50%',
                                      lineHeight: '1.2em',
                                      textAlign: 'center',
                                      fontSize: '1.1em'
                                    }}
                                  >
                                    !
                                  </span>
                                </div>
                            )}
                            <NavLink href="#"
                                     style={{padding: '34px'}}
                                     className={classnames({
                                       active: activeTab === 1,
                                       done: (activeTab <= 4 && activeTab >= 0)
                                     }, "fs-15 lh-1")}
                                     onClick={() => {
                                       toggleTab(1);
                                     }}
                            >
                              <i className="ri-user-2-line fs-16 p-2 bg-primary-subtle text-primary rounded-circle align-middle me-2"></i>
                              Настройки интеграции
                            </NavLink>
                          </NavItem>
                          <NavItem role="presentation" className="position-relative">
                            {Object.keys(integration?.api_not_synced_orders || {}).length > 0 && activeTab !== 2 && (
                              <div className="position-absolute d-inline-block"
                                   style={{right: '10px', top: '10px', color: '#ff5454'}}>
                                Не синхр. заказы
                                &nbsp;
                                <span className="fw-bold"
                                  style={{
                                    display: 'inline-block',
                                    width: '1.3em',
                                    height: '1.3em',
                                    border: '2px solid #ff5454',
                                    borderRadius: '50%',
                                    lineHeight: '1.2em',
                                    textAlign: 'center',
                                    fontSize: '1.1em'
                                  }}
                                >
                                !
                              </span>
                              </div>
                            )}
                            <NavLink href="#"
                                     style={{padding: '34px'}}
                                     className={classnames({
                                       active: activeTab === 2,
                                       done: activeTab <= 4 && activeTab > 1
                                     }, "fs-15 lh-1")}
                                     onClick={() => {
                                       toggleTab(2);
                                     }}
                            >
                              <i className="ri-truck-line fs-16 p-2 bg-primary-subtle text-primary rounded-circle align-middle me-2"></i>
                              Журнал событий
                            </NavLink>
                          </NavItem>
                          {/*<NavItem role="presentation">
                            <NavLink href="#"
                                     className={classnames({
                                       active: activeTab === 3,
                                       done: activeTab <= 4 && activeTab > 2
                                     }, "fs-15 p-3")}
                                     onClick={() => {
                                       toggleTab(3);
                                     }}
                            >
                              <i className="ri-bank-card-line fs-16 p-2 bg-primary-subtle text-primary rounded-circle align-middle me-2"></i>
                              Payment Info
                            </NavLink>
                          </NavItem>*/}
                          {/*<NavItem role="presentation">
                            <NavLink href="#"
                                     className={classnames({
                                       active: activeTab === 4,
                                       done: activeTab <= 4 && activeTab > 3
                                     }, "fs-15 p-3")}
                                     onClick={() => {
                                       toggleTab(4);
                                     }}
                            >
                              <i className="ri-checkbox-circle-line fs-16 p-2 bg-primary-subtle text-primary rounded-circle align-middle me-2"></i>
                              Finish
                            </NavLink>
                          </NavItem>*/}
                        </Nav>
                      </div>

                      <TabContent activeTab={activeTab}>
                        <TabPane tabId={1} id="pills-bill-info">
                          <div>
                            <h5 className="mb-1">Основные настройки</h5>
                            <p className="text-muted mb-4">
                              Пожалуйста заполните поля ниже для активации
                            </p>
                            {integration?.statuses_filled === false && integration?.enabled === true && (
                              <div className="mb-4" style={{color: '#ff5454'}}>
                                <span
                                  className="fw-bold"
                                  style={{
                                    display: 'inline-block',
                                    width: '1.3em',
                                    height: '1.3em',
                                    border: '2px solid #ff5454',
                                    borderRadius: '50%',
                                    lineHeight: '1.2em',
                                    textAlign: 'center',
                                    fontSize: '1.1em'
                                  }}
                                >
                                  !
                                </span>
                                &nbsp;
                                Привяжите все статусы чтобы интеграция начала работать.
                              </div>
                            )}

                          </div>

                          <div>
                            <Row>
                              <Col sm={6}>
                                <div className="mb-3">
                                  <Label
                                      htmlFor="integration-token"
                                      className="form-label"
                                  >
                                    API токен
                                  </Label>
                                  <Input
                                      name="api_token"
                                      type="text"
                                      className="form-control"
                                      id="integration-token"
                                      placeholder="Введите ваш токен из Каспи кабинета"
                                      onChange={handleInputChange}
                                      value={integration?.api_token || ""}
                                  />
                                </div>
                              </Col>

                              <Col sm={6}>
                                <div className="mb-3">
                                <Label
                                      htmlFor="integration-enabled"
                                      className="form-label d-block"
                                  >
                                    Интеграция включена
                                  </Label>
                                  <Switch
                                      name="enabled"
                                      style={{padding: '8px'}}
                                      // readOnly={true}
                                      // disabled={true}
                                      type="checkbox"
                                      className="form-control"
                                      id="integration-enabled"
                                      checked={integration.enabled}
                                      onChange={handleInputChange}
                                      // placeholder="Enter last name"
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col sm={6}>
                                <div className="mb-3">
                                  <Label
                                      htmlFor="integration-archive"
                                      className="form-label"
                                  >
                                    Создавать архивные заказы за последние
                                    {/*<span className="text-muted">(Optional)</span>*/}
                                  </Label>
                                  <select
                                      name="last_days_create_archive"
                                      className="form-select"
                                      data-plugin="choices"
                                      id="integration-archive"
                                      value={integration.last_days_create_archive}
                                      onChange={handleInputChange}
                                  >
                                    {integration?.last_days_create_archive_options &&
                                        integration?.last_days_create_archive_options.map(([value, name]) => (
                                        <option key={value} value={value}>{name}</option>
                                    ))}
                                  </select>
                                  {/*
                                  <select
                                      className="form-select"
                                      data-plugin="choices"
                                      value={getStatusForFixedStatus(value)}
                                      onChange={(event) => handleStatusFieldChange(value, event)}
                                  >
                                    <option value="" style={{fontWeight: 'bold'}}>- не выбрано -</option>
                                    {statuses.map(({id, name, mark}) => (
                                        <option key={id} value={id}>{name}</option>
                                    ))}
                                  </select>
                                  */}
                                </div>
                              </Col>

                              <Col sm={6}>
                                <div className="mb-3">
                                  <Label
                                      htmlFor="billinginfo-phone"
                                      className="form-label"
                                  >
                                    Токен валидный
                                  </Label>
                                  <ValidityIndicator isValid={integration?.token_valid === true} />
                                  {/*<Input*/}
                                  {/*    type="text"*/}
                                  {/*    className="form-control"*/}
                                  {/*    id="billinginfo-phone"*/}
                                  {/*    placeholder="Enter phone no."*/}
                                  {/*/>*/}
                                </div>
                              </Col>
                            </Row>

                            {/*<div className="mb-3">
                              <Label
                                  htmlFor="billinginfo-address"
                                  className="form-label"
                              >
                                Address
                              </Label>
                              <textarea
                                  className="form-control"
                                  id="billinginfo-address"
                                  placeholder="Enter address"
                                  rows="3"
                              ></textarea>
                            </div>*/}

                            <Row>
                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="kaspi_company" className="form-label">
                                    Компания Kaspi
                                  </Label>
                                  <Input
                                      type="text"
                                      className="form-control"
                                      id="kaspi_company"
                                      placeholder="Введите название компании"
                                      name="kaspi_company"
                                      onChange={handleInputChange}
                                      value={integration?.kaspi_company || ""}
                                  />
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="kaspi_merchant_id" className="form-label">
                                    Merchant ID Kaspi
                                  </Label>
                                  <Input
                                      type="text"
                                      autoComplete="off"
                                      className="form-control"
                                      id="kaspi_merchant_id"
                                      placeholder="Введите ваш merchant id"
                                      name="kaspi_merchant_id"
                                      onChange={handleInputChange}
                                      value={integration?.kaspi_merchant_id || ""}
                                  />
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="api_save_customer" className="form-label d-block">
                                    Автоматически сохранять клиентов
                                  </Label>
                                  <Input
                                      style={{padding: '8px'}}
                                      type="checkbox"
                                      className="form-control"
                                      id="api_save_customer"
                                      name="api_save_customer"
                                      onChange={handleInputChange}
                                      checked={integration.api_save_customer}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={4}>
                                <div >
                                  <Label htmlFor="kaspi_password" className="form-label">
                                    Пароль Kaspi
                                  </Label>
                                  <Input
                                      autoComplete="new-password"
                                      type="password"
                                      className="form-control"
                                      id="kaspi_password"
                                      placeholder="Введите ваш пароль Kaspi"
                                      name="kaspi_password"
                                      onChange={handleInputChange}
                                      value={integration?.kaspi_password || ""}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="my-4 align-items-center">
                              <Col md={4}>
                                <h5 className="mb-lg-0 mx-lg-2">Обновление файла XML</h5>
                              </Col>
                              <Col md={1}>
                                <button
                                    type="button"
                                    className={`lh-1 btn ${integration?.pricelist_update_enabled ? 'btn-outline-warning' : 'btn-outline-primary'}`}
                                    name="pricelist_update_enabled"
                                    value={integration?.pricelist_update_enabled ? 'false' : 'true'}
                                    onClick={handleInputChange}
                                >
                                  {integration?.pricelist_update_enabled ? 'Выключить автовыгрузку' : 'Включить автовыгрузку'}
                                </button>
                              </Col>

                              <Card style={{width: '97%', margin: '0 auto'}}>
                                <CardHeader>
                                  <p style={{maxWidth: '500px'}}>Ссылка на прайс-лист в формате XML для Kaspi, который содержит список ваших товаров,
                                  цены и наличие на складах. Выгрузка остатков и цен выполняется каждые 15 мин.</p>
                                </CardHeader>
                                <CardBody>
                                  <div className="mb-3">
                                    <Label htmlFor="pricelist_xml" className="form-label">
                                      XML прайс-лист
                                    </Label>
                                    <Row>
                                      <Col md={8}>
                                        <Input
                                            readOnly={true}
                                            style={{padding: '8px'}}
                                            type="text"
                                            className="form-control"
                                            id="pricelist_xml"
                                            name="pricelist_xml"
                                            value={integration?.pricelist_xml || ""}
                                        />
                                      </Col>
                                      <Col md={4}>
                                        <button
                                            type="button"
                                            className="btn btn-outline-primary btn-sm fs-13"
                                            style={{padding: '2px 10px'}}
                                            onClick={handleUploadProducts}
                                        >
                                          Выгрузить
                                        </button>
                                        {integration?.last_uploaded_formatted &&
                                            <div className="text-muted fs-12" style={{fontFamily: 'Montserrat, sans-serif'}}>
                                              Последняя выгрузка: {integration.last_uploaded_formatted}
                                            </div>
                                        }
                                      </Col>
                                    </Row>
                                  </div>
                                  {/*<div className="mb-3">
                                    <Label htmlFor="pricelist_xlsx" className="form-label">
                                      EXCEL прайс-лист
                                    </Label>
                                    <Row>
                                      <Col md={10}>
                                        <Input
                                            readOnly={true}
                                            style={{padding: '8px'}}
                                            type="text"
                                            className="form-control"
                                            id="pricelist_xlsx"
                                            name="pricelist_xlsx"
                                            value={integration?.pricelist_xlsx || ""}
                                        />
                                      </Col>
                                    </Row>
                                  </div>*/}
                                </CardBody>
                              </Card>
                            </Row>


                            <div className="d-flex align-items-start gap-3 mt-3">
                              <button
                                  type="submit"
                                  className="btn btn-success lh-1"
                                  // onClick={() => {
                                  //   toggleTab(activeTab + 1);
                                  // }}
                              >
                                {/*<i className="ri-truck-line label-icon align-middle fs-16 ms-2"></i>*/}
                                Сохранить настройки
                              </button>
                              <button
                                  type="button"
                                  className="btn btn-info btn-label right ms-auto nexttab lh-1"
                                  onClick={() => {
                                    toggleTab(activeTab + 1);
                                  }}
                              >
                                <i className="ri-truck-line label-icon align-middle fs-16 ms-2"></i>
                                Перейти к журналу
                              </button>
                            </div>
                          </div>
                        </TabPane>

                        <TabPane tabId={2}>
                          <div>
                            <h5 className="mb-1">Журнал событий интеграции</h5>
                            <p className="text-muted mb-4">
                              Здесь отображается всё что происходит в пределах интеграции
                            </p>
                          </div>
                          {Object.keys(integration?.api_not_synced_orders || {}).length > 0 && (
                              <div className="mt-4" style={{color: 'rgb(255, 84, 84)'}}>
                              <span className="fw-bold"
                                    style={{
                                      display: 'inline-block',
                                      width: '1.3em',
                                      height: '1.3em',
                                      border: '2px solid #ff5454',
                                      borderRadius: '50%',
                                      lineHeight: '1.2em',
                                      textAlign: 'center',
                                      fontSize: '1.1em'
                                    }}
                              >
                                !
                              </span>
                                &nbsp;
                                Не синхронизированные заказы:
                                <ul>
                                  {integration?.api_not_synced_orders &&
                                      Object.keys(integration.api_not_synced_orders).length > 0 &&
                                      Object.entries(integration.api_not_synced_orders).map(([name, val], index) => (
                                    <li key={index}>{name}: {val}</li>
                                  ))}
                                </ul>
                              </div>
                          )}

                          <EventLogTable events={integration?.event_logs} rows={5}/>

                          {/*<div className="mt-4">
                            <div className="d-flex align-items-center mb-2">
                              <div className="flex-grow-1">
                                <h5 className="fs-14 mb-0">Saved Address</h5>
                              </div>
                              <div className="flex-shrink-0">
                                <button
                                    type="button"
                                    className="btn btn-sm btn-success mb-3"
                                    onClick={togglemodal}
                                >
                                  Add Address
                                </button>
                              </div>
                            </div>
                            <Row className="gy-3">
                              <Col lg={4} sm={6}>
                                <div className="form-check card-radio">
                                  <Input
                                      id="shippingAddress01"
                                      name="shippingAddress"
                                      type="radio"
                                      className="form-check-input"
                                      defaultChecked
                                  />
                                  <Label
                                      className="form-check-label"
                                      htmlFor="shippingAddress01"
                                  >
                                  <span className="mb-4 fw-semibold d-block text-muted text-uppercase">
                                    Home Address
                                  </span>

                                    <span className="fs-14 mb-2 d-block">
                                    Marcus Alfaro
                                  </span>
                                    <span className="text-muted fw-normal text-wrap mb-1 d-block">
                                    4739 Bubby Drive Austin, TX 78729
                                  </span>
                                    <span className="text-muted fw-normal d-block">
                                    Mo. 012-345-6789
                                  </span>
                                  </Label>
                                </div>
                                <div className="d-flex flex-wrap p-2 py-1 bg-light rounded-bottom border mt-n1">
                                  <div>
                                    <Link
                                        to="#"
                                        className="d-block text-body p-1 px-2"
                                        onClick={togglemodal}
                                    >
                                      <i className="ri-pencil-fill text-muted align-bottom me-1"></i>
                                      Edit
                                    </Link>
                                  </div>
                                  <div>
                                    <Link
                                        to="#"
                                        className="d-block text-body p-1 px-2"
                                        onClick={toggledeletemodal}
                                    >
                                      <i className="ri-delete-bin-fill text-muted align-bottom me-1"></i>
                                      Remove
                                    </Link>
                                  </div>
                                </div>
                              </Col>
                              <Col lg={4} sm={6}>
                                <div className="form-check card-radio">
                                  <Input
                                      id="shippingAddress02"
                                      name="shippingAddress"
                                      type="radio"
                                      className="form-check-input"
                                  />
                                  <Label
                                      className="form-check-label"
                                      htmlFor="shippingAddress02"
                                  >
                                  <span className="mb-4 fw-semibold d-block text-muted text-uppercase">
                                    Office Address
                                  </span>

                                    <span className="fs-14 mb-2 d-block">
                                    James Honda
                                  </span>
                                    <span className="text-muted fw-normal text-wrap mb-1 d-block">
                                    1246 Virgil Street Pensacola, FL 32501
                                  </span>
                                    <span className="text-muted fw-normal d-block">
                                    Mo. 012-345-6789
                                  </span>
                                  </Label>
                                </div>
                                <div className="d-flex flex-wrap p-2 py-1 bg-light rounded-bottom border mt-n1">
                                  <div>
                                    <Link
                                        to="#"
                                        className="d-block text-body p-1 px-2"
                                        onClick={togglemodal}
                                    >
                                      <i className="ri-pencil-fill text-muted align-bottom me-1"></i>
                                      Edit
                                    </Link>
                                  </div>
                                  <div>
                                    <Link
                                        to="#"
                                        className="d-block text-body p-1 px-2"
                                        onClick={toggledeletemodal}
                                    >
                                      <i className="ri-delete-bin-fill text-muted align-bottom me-1"></i>
                                      Remove
                                    </Link>
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <div className="mt-4">
                              <h5 className="fs-14 mb-3">Shipping Method</h5>

                              <Row className="g-4">
                                <Col lg={6}>
                                  <div className="form-check card-radio">
                                    <Input
                                        id="shippingMethod01"
                                        name="shippingMethod"
                                        type="radio"
                                        className="form-check-input"
                                    />
                                    <Label
                                        className="form-check-label"
                                        htmlFor="shippingMethod01"
                                    >
                                    <span className="fs-20 float-end mt-2 text-wrap d-block">
                                      Free
                                    </span>
                                      <span className="fs-14 mb-1 text-wrap d-block">
                                      Free Delivery
                                    </span>
                                      <span className="text-muted fw-normal text-wrap d-block">
                                      Expected Delivery 3 to 5 Days
                                    </span>
                                    </Label>
                                  </div>
                                </Col>
                                <Col lg={6}>
                                  <div className="form-check card-radio">
                                    <Input
                                        id="shippingMethod02"
                                        name="shippingMethod"
                                        type="radio"
                                        className="form-check-input"
                                        defaultChecked
                                    />
                                    <Label
                                        className="form-check-label"
                                        htmlFor="shippingMethod02"
                                    >
                                    <span className="fs-20 float-end mt-2 text-wrap d-block">
                                      $24.99
                                    </span>
                                      <span className="fs-14 mb-1 text-wrap d-block">
                                      Express Delivery
                                    </span>
                                      <span className="text-muted fw-normal text-wrap d-block">
                                      Delivery within 24hrs.
                                    </span>
                                    </Label>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </div>*/}

                          <div className="d-flex align-items-start gap-3 mt-4">
                            <button
                                type="button"
                                className="btn btn-light btn-label previestab"
                                onClick={() => {
                                  toggleTab(activeTab - 1);
                                }}
                            >
                              <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>
                              Вернуться к настройкам
                            </button>
                            {/*<button
                                type="button"
                                className="btn btn-primary btn-label right ms-auto nexttab"
                                onClick={() => {
                                  toggleTab(activeTab + 1);
                                }}
                            >
                              <i className="ri-bank-card-line label-icon align-middle fs-16 ms-2"></i>
                              Continue to Payment
                            </button>*/}
                          </div>
                        </TabPane>

                        <TabPane tabId={3}>
                          <div>
                            <h5 className="mb-1">Payment Selection</h5>
                            <p className="text-muted mb-4">
                              Please select and enter your billing information
                            </p>
                          </div>

                          <Row className="g-4">
                            <Col lg={4} sm={6}>
                              <div>
                                <div className="form-check card-radio">
                                  <Input
                                      id="paymentMethod01"
                                      name="paymentMethod"
                                      type="radio"
                                      className="form-check-input"
                                  />
                                  <Label
                                      className="form-check-label"
                                      htmlFor="paymentMethod01"
                                  >
                                  <span className="fs-16 text-muted me-2">
                                    <i className="ri-paypal-fill align-bottom"></i>
                                  </span>
                                    <span className="fs-14 text-wrap">
                                    Paypal
                                  </span>
                                  </Label>
                                </div>
                              </div>
                            </Col>
                            <Col lg={4} sm={6}>
                              <div>
                                <div className="form-check card-radio">
                                  <Input
                                      id="paymentMethod02"
                                      name="paymentMethod"
                                      type="radio"
                                      className="form-check-input"
                                      defaultChecked
                                  />
                                  <Label
                                      className="form-check-label"
                                      htmlFor="paymentMethod02"
                                  >
                                  <span className="fs-16 text-muted me-2">
                                    <i className="ri-bank-card-fill align-bottom"></i>
                                  </span>
                                    <span className="fs-14 text-wrap">
                                    Credit / Debit Card
                                  </span>
                                  </Label>
                                </div>
                              </div>
                            </Col>

                            <Col lg={4} sm={6}>
                              <div>
                                <div className="form-check card-radio">
                                  <Input
                                      id="paymentMethod03"
                                      name="paymentMethod"
                                      type="radio"
                                      className="form-check-input"
                                  />
                                  <Label
                                      className="form-check-label"
                                      htmlFor="paymentMethod03"
                                  >
                                  <span className="fs-16 text-muted me-2">
                                    <i className="ri-money-dollar-box-fill align-bottom"></i>
                                  </span>
                                    <span className="fs-14 text-wrap">
                                    Cash on Delivery
                                  </span>
                                  </Label>
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <div
                              className="collapse show"
                              id="paymentmethodCollapse"
                          >
                            <Card className="p-4 border shadow-none mb-0 mt-4">
                              <Row className="gy-3">
                                <Col md={12}>
                                  <Label htmlFor="cc-name" className="form-label">
                                    Name on card
                                  </Label>
                                  <Input
                                      type="text"
                                      className="form-control"
                                      id="cc-name"
                                      placeholder="Enter name"
                                  />
                                  <small className="text-muted">
                                    Full name as displayed on card
                                  </small>
                                </Col>

                                <Col md={6}>
                                  <Label
                                      htmlFor="cc-number"
                                      className="form-label"
                                  >
                                    Credit card number
                                  </Label>
                                  <Input
                                      type="text"
                                      className="form-control"
                                      id="cc-number"
                                      placeholder="xxxx xxxx xxxx xxxx"
                                  />
                                </Col>

                                <Col md={3}>
                                  <Label
                                      htmlFor="cc-expiration"
                                      className="form-label"
                                  >
                                    Expiration
                                  </Label>
                                  <Input
                                      type="text"
                                      className="form-control"
                                      id="cc-expiration"
                                      placeholder="MM/YY"
                                  />
                                </Col>

                                <Col md={3}>
                                  <Label htmlFor="cc-cvv" className="form-label">
                                    CVV
                                  </Label>
                                  <Input
                                      type="text"
                                      className="form-control"
                                      id="cc-cvv"
                                      placeholder="xxx"
                                  />
                                </Col>
                              </Row>
                            </Card>
                            <div className="text-muted mt-2 fst-italic">

                              <FeatherIcon
                                  icon="lock"
                                  className="text-muted icon-xs"
                              />{" "}

                              Your transaction is secured with SSL encryption
                            </div>
                          </div>

                          <div className="d-flex align-items-start gap-3 mt-4">
                            <button
                                type="button"
                                className="btn btn-light btn-label previestab"
                                onClick={() => {
                                  toggleTab(activeTab - 1);
                                }}
                            >
                              <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>
                              Back to Shipping
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary btn-label right ms-auto nexttab"
                                onClick={() => {
                                  toggleTab(activeTab + 1);
                                }}
                            >
                              <i className="ri-shopping-basket-line label-icon align-middle fs-16 ms-2"></i>
                              Complete Order
                            </button>
                          </div>
                        </TabPane>

                        <TabPane tabId={4} id="pills-finish">
                          <div className="text-center py-5">
                            <div className="mb-4">
                              <lord-icon
                                  src="https://cdn.lordicon.com/lupuorrc.json"
                                  trigger="loop"
                                  colors="primary:#0ab39c,secondary:#405189"
                                  style={{width: "120px", height: "120px"}}
                              ></lord-icon>
                            </div>
                            <h5>Thank you ! Your Order is Completed !</h5>
                            <p className="text-muted">
                              You will receive an order confirmation email with
                              details of your order.
                            </p>

                            <h3 className="fw-semibold">
                              Order ID:{" "}
                              <Link
                                  to="/apps-ecommerce-order-details"
                                  className="text-decoration-underline"
                              >
                                VZ2451
                              </Link>
                            </h3>
                          </div>
                        </TabPane>
                      </TabContent>
                    </Form>
                  </CardBody>
                </Card>
              </Col>

              <Col xl={4}>
                <Card>
                  <CardHeader>
                    <div className="d-flex justify-content-between align-items-center">
                      <div> {/*className="flex-grow-1"*/}
                        <h5 className="card-title mb-0">Привязка статусов</h5>
                      </div>
                      <div> {/*className="flex-grow-1"*/}
                        <button
                            className="btn btn-success btn-sm fs-13"
                            onClick={handleSaveStatusesClick}
                        >Сохранить</button>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <div className="table-responsive table-card">
                    <table className="table table-borderless align-middle mb-0">
                        <thead className="table-light text-muted">
                        <tr>
                          <th scope="col">
                            Статус в Kaspi магазине
                          </th>
                          {/*<th scope="col">Product Info</th>*/}
                          <th scope="col" className="text-end">
                            Статус в CRM
                          </th>
                        </tr>
                        {errors.mapped_statuses?.user_status || errors.mapped_statuses?.fixed_status ? (
                          <tr>
                            <th colSpan="2">
                              <div style={{ color: "#ed5e5e", width: "100%" }}>
                                <ul style={{ marginBottom: 0 }}>
                                  {errors.mapped_statuses?.user_status && (<li>{errors.mapped_statuses.user_status}</li>)}
                                  {errors.mapped_statuses?.fixed_status && (<li>{errors.mapped_statuses.fixed_status}</li>)}
                                </ul>
                              </div>
                            </th>
                          </tr>
                        ) : null}
                        </thead>
                        <tbody>
                        {integration?.kaspi_statuses &&
                            Object.keys(integration.kaspi_statuses).length > 0 &&
                            Object.entries(integration.kaspi_statuses).map(([value, label], key) => (
                            <React.Fragment key={key}>
                              <tr>
                                <td style={{fontWeight: '500'}}>{label}</td>
                                <td className="text-end">
                                  <select
                                      className="form-select"
                                      data-plugin="choices"
                                      value={getStatusForFixedStatus(value)}
                                      onChange={(event) => handleStatusFieldChange(value, event)}
                                  >
                                    <option value="" style={{fontWeight: 'bold'}}>- не выбрано -</option>
                                    {statuses.length > 0 && statuses.map(({id, name, mark}) => (
                                        <option key={id} value={id}>{name}</option>
                                    ))}
                                  </select>
                                  <div className="invalid" style={{color: 'red', lineHeight: 1.1, marginTop: '5px'}}>
                                    {/*Поле не может быть пустым*/}
                                    {errors?.mapped_statuses
                                        ? errors?.mapped_statuses[key]?.user_status
                                        : null}
                                  </div>
                                </td>
                              </tr>
                            </React.Fragment>
                        ))}
                        </tbody>
                    </table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <ToastContainer closeButton={false}/>
          </Container>
        </div>
        {/* modal Delete Address */}
        <Modal
            isOpen={deletemodal}
            role="dialog"
            autoFocus={true}
            centered
            id="removeItemModal"
            toggle={toggledeletemodal}
        >
          <ModalHeader toggle={() => {
            setDeleteModal(!deletemodal);
          }}>
          </ModalHeader>
          <ModalBody>
            <div className="mt-2 text-center">
              <lord-icon
                  src="https://cdn.lordicon.com/gsqxdxog.json"
                  trigger="loop"
                  colors="primary:#0ab39c,secondary:#405189"
                  style={{width: "100px", height: "100px"}}
              ></lord-icon>
              <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                <h4>Are you Sure ?</h4>
                <p className="text-muted mx-4 mb-0">
                  Are you Sure You want to Remove this Address ?
                </p>
              </div>
            </div>
            <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
              <button
                  type="button"
                  className="btn w-sm btn-light"
                  onClick={() => {
                    setDeleteModal(!deletemodal);
                  }}
              >
                Close
              </button>
              <button type="button" className="btn w-sm btn-danger" onClick={() => {
                setDeleteModal(!deletemodal);
              }}>
                Yes, Delete It!
              </button>
            </div>
          </ModalBody>
        </Modal>

        {/* modal Add Address */}
        <Modal
            isOpen={modal}
            role="dialog"
            autoFocus={true}
            centered
            id="addAddressModal"
            toggle={togglemodal}
        >
          <ModalHeader
              toggle={() => {
                setModal(!modal);
              }}
          >
            <h5 className="modal-title" id="addAddressModalLabel">
              Address
            </h5>
          </ModalHeader>
          <ModalBody>
            <div>
              <div className="mb-3">
                <Label for="addaddress-Name" className="form-label">
                  Name
                </Label>
                <Input
                    type="text"
                    className="form-control"
                    id="addaddress-Name"
                    placeholder="Enter Name"
                />
              </div>

              <div className="mb-3">
                <Label for="addaddress-textarea" className="form-label">
                  Address
                </Label>
                <textarea
                    className="form-control"
                    id="addaddress-textarea"
                    placeholder="Enter Address"
                    rows="2"
                ></textarea>
              </div>

              <div className="mb-3">
                <Label for="addaddress-Name" className="form-label">
                  Phone
                </Label>
                <Input
                    type="text"
                    className="form-control"
                    id="addaddress-Name"
                    placeholder="Enter Phone No."
                />
              </div>

              <div className="mb-3">
                <Label for="state" className="form-label">
                  Address Type
                </Label>
                <select className="form-select" id="state" data-plugin="choices">
                  <option value="homeAddress">Home (7am to 10pm)</option>
                  <option value="officeAddress">Office (11am to 7pm)</option>
                </select>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  setModal(!modal);
                }}
            >
              Close
            </button>
            <button
                type="button"
                className="btn btn-success"
                onClick={() => {
                  setModal(!modal);
                }}
            >
              Save
            </button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
  );
};

export default KaspiAPIIntegration;
