
//REGISTER
export const POST_JWT_REGISTER = "/api/users/";
export const POST_JWT_CONFIRM_EMAIL = "api/users/confirm_email/";

//LOGIN
export const POST_JWT_LOGIN = "/auth/token/";
export const GET_ME = "/api/users/me/";
export const POST_EDIT_ME = GET_ME;

//INVITES
export const GET_INVITATIONS = "/api/users/invitations/";
export const GET_ACCOUNTS = "/api/users/invitations/accounts/";
export const GET_CHANGE_ACCOUNT = "/api/users/invitations/change_account/";
// export const GET_ACCEPT_INVITATION = "/api/users/invitations/accept/";

// Supplier
export const GET_SUPPLIER = "/api/core/supplier/";
// Customer
export const GET_CUSTOMER = "/api/core/customer/";
// Storage
export const GET_STORAGE = "/api/core/storage/";
export const GET_REMAINS = "/api/core/storageproduct/";
// Status
export const GET_STATUS = "/api/core/status/";
// Sales Channel
export const GET_SALES_CHANNEL = "/api/core/sales_channel/";
// Products
export const GET_PRODUCT = "/api/core/product/";
export const POST_IMPORT_PRODUCTS = "/api/core/update_pricelist/";
export const UPDATE_FROM_KASPI = "/api/api_integration/process/update_from_kaspi/";
export const POST_UPDATE_ON_STORAGE = "/api/core/update_on_storage/";

// Reception
export const GET_RECEPTION = "/api/core/reception/";
// Order
export const GET_ORDER = "/api/core/order/";
export const GET_CALCULATE_PROFIT = "/api/core/calculate-profit/";
export const GET_ANNUAL_PROFIT_STATISTICS = "/api/core/annual-profit-statistics/";
export const GET_PROFIT_FORECAST = "/api/core/profit-forecast/";
export const GET_CALCULATE_TURNOVER = "/api/core/calculate-turnover/";

// Expenses
export const GET_EXPENSE_CATEGORIES = "/api/core/expense-categories/";
export const GET_EXPENSES = "/api/core/expenses/";
// Integration
export const GET_INTEGRATION = "/api/api_integration/";
export const GET_GENERATE_PRICELIST = "/api/api_integration/generate_pricelist/";
// ExternalBot
export const GET_BOT_INTEGRATION = "/api/external_bot/";
export const GET_BOT_PRICELIST = "https://pricesbot.com/api/pricelist/";
export const GET_BOT_STATS = "https://pricesbot.com/api/bot-stats/";
export const BOT_SETTINGS = "https://pricesbot.com/api/settings/";
export const MASS_PRICE_CHANGE = "/api/external_bot/mass_price_change/";

export const POST_PASSWORD_CHANGE = "/api/users/change_password/";
