import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@mui/material';

const Filter = ({ metaData, handleChange, withInitialFilter=false, botFilters, disabled=false }) => {
    if (!metaData || !metaData.f_select_data) {
        return <CircularProgress />;
    }

    // Преобразуем данные фильтра
    const filterOptions = Object.keys(metaData.f_select_data).map(key => ({
        value: key,
        label: `${metaData.f_select_data[key].name} (${metaData.f_select_data[key].count})`
    }));

    return (
        <FormControl sx={{ minWidth: 120, flexGrow: 1, width: '100%' }} size="small">
            <InputLabel id="demo-select-small-label">{withInitialFilter ? botFilters || 'Все товары' : (metaData.f_name || 'Выберите фильтр')}</InputLabel>
            {/*<InputLabel id="demo-select-small-label">{(withInitialFilter && !botFilters) ? 'Все товары' : (metaData.f_name || 'Выберите фильтр')}</InputLabel>*/}
            <Select
                disabled={disabled}
                labelId="demo-select-small-label"
                id="demo-select-small"
                // value={(withInitialFilter && !botFilters) ? 'initial' : (metaData.f || 'all')}
                value={withInitialFilter ? botFilters || null : (metaData.f || 'all')}
                // label={(withInitialFilter && !botFilters) ? 'Все товары' : (metaData.f_name || 'Выберите фильтр')}
                label={withInitialFilter ? botFilters || 'Все товары' : (metaData.f_name || 'Выберите фильтр')}
                onChange={handleChange}
            >
                {withInitialFilter &&
                    <MenuItem key="initial" value={null}>
                        Все товары
                    </MenuItem>
                }
                {filterOptions.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default Filter;